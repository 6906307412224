import Header from './components/Header';

function App() {
  return (
    <div className="page d-flex flex-row flex-column-fluid">
				<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
					<Header></Header>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						
					</div>
				</div>
			</div>

  );
}

export default App;
