import React from 'react';

const Navbar = () => {
    return (
        <div className="d-flex align-items-stretch flex-shrink-0">
	        <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                <div className="flex-equal text-end ms-1">
                    <a href="/Accout/Login" class="btn btn-success btn-sm">Sign In</a>
                </div>
	        </div>
        </div>
    )
}

export default Navbar;