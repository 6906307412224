import React from 'react';
import LogoImage from '../assets/media/logos/OfficeSquaresLogo.svg';

const Logo = () => {
    return (
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1"> <a className="menu-link py-3" href="https://dev.officesquares.com"><img alt="Logo" src={LogoImage} className="h-20px h-lg-30px"/></a></h1>
        </div>
    )
}

export default Logo;