import React from 'react';
import Logo from './Logo';
import Navbar from './Navbar';
import Menu from './Menu';

const Header = () => {
    return (
        <div id="os_header" className="header align-items-stretch">
            <div className="container-xxl d-flex align-items-stretch justify-content-between">
                <Logo />
                <Menu></Menu>
                <Navbar></Navbar>
            </div>
        </div>
    )
}

export default Header;